$( document ).ready(function() {


 
    //slider mainPageSlider
    $('.mainPageSlider').owlCarousel({
      items:1,
        lazyLoad:true,
        autoplayHoverPause:true,
        loop:true,
        mouseDrag : false,
        margin:10,
        autoplay:true,
        autoplayTimeout:4000,
        smartSpeed:1250,
        nav:false,
        dots:true,
        //navText: [$('.owl-left'),$('.owl-right')]
  });
  //end slider mainPageSlider



    //slider MainSlajderMawig
    $('.MainSlajderMawig').owlCarousel({
      loop:true,
      margin:10,
      responsiveClass:true,
      nav:true,
      dots:false,
      loop:true,
      navText: [$('.owl-left'),$('.owl-right')],
      responsive:{
          0:{
              items:1,
              nav:true
          },
          600:{
              items:3,
              nav:false
          },
          1000:{
              items:3,
              nav:true,
              loop:false
          }
      }
  });
  //end slider photoSlider

    
      //animacja wejscia dla stron
      //document.body.classList.add('fadeIn');

    //header scroll fixed
    $(window).scroll(function(){
        if ($(window).scrollTop() >= 150) {
          $('header').addClass('header--fixed');
        }
        else {
          $('header').removeClass('header--fixed');
        }
    });
    //end header scroll fixed

    //zmiana wygladu headera gdy nie jest wlaczony slider
      if ($('.mainSlider').length === 0){
        $('body').css('padding-top','0px');
      }
    //

    /// klikalne dropdow
      // $('.dropdown > a').click(function(e){
      //   var url = e.target.getAttribute('href');
      //   window.location.href = url;
      // })
    ///

    //burger menu 
    $('.burger').click(function(){
        $(this).toggleClass('active');
        $('.nav').slideToggle('fast');
    })
    //end burger menu 

	
  //slider galeriabox-slider
    $('.galeriabox-slider').slick({
      infinite: true,
      speed: 900,
      lazyLoad: 'ondemand',
      centerMode: true,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 2000,
		dots:false,
  });
    
    



    //panelMobileBottom
    var lastScrollTop = 0;
      $(window).scroll(function(event){
        if( window.screen.width <= 768 ){
          var st = $(this).scrollTop();
          if (st > lastScrollTop){
              // downscroll code
              $('.mobileBottomPanel').slideDown()
          } else {
              // upscroll code
              //$('.mobileBottomPanel').stop().slideUp()
          }
        }else{
          $('.mobileBottomPanel').stop().slideUp()
        }
      });

/* uruchamianie filmów */
    $(".SetFilm1 .film__content__player").click(function(){
      $(".SetFilm1 .film__content").addClass('novisible');
      $(".SetFilm1 .film__film").addClass('showvisible');
      var videoURL = $('#video1').prop('src');
      videoURL += "&autoplay=1";
      $('#video1').prop('src',videoURL); 
    });
    $(".SetFilm2 .film__content__player").click(function(){
        $(".SetFilm2 .film__content").addClass('novisible');
        $(".SetFilm2 .film__film").addClass('showvisible');
        var videoURL = $('#video2').prop('src');
        videoURL += "&autoplay=1";
        $('#video2').prop('src',videoURL); 
    });
    $(".SetFilm3 .film__content__player").click(function(){
        $(".SetFilm3 .film__content").addClass('novisible');
        $(".SetFilm3 .film__film").addClass('showvisible');
        var videoURL = $('#video3').prop('src');
        videoURL += "&autoplay=1";
        $('#video3').prop('src',videoURL);
    });

    $("#product_slider").lightSlider({
      gallery:true, 
      item:1, 
      auto:false, 
      loop:true, 
      thumbItem: 4,
  });
});

/* thumb slider */
//$('#product_slider').thumb_slider();
